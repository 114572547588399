* {
    box-sizing: border-box;
}

body {
    margin: 0;
    height: 100vh;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;
}

.app {
    position: relative;

    div.animation {
        position: relative;
        background: transparent;
        div.show-controls {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            position: absolute;
            z-index: 10;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                -webkit-appearance: none;
                border: none;
                background: transparent;
                font-size: 1.2rem;
                padding: 1rem 0;
                cursor: pointer;
                transition: all 250ms;
                width: 50%;
                font-weight: 700;
                &:hover {
                    transition: all 250ms;
                    color: #ECA400;
                }
                &:focus {
                    outline: none;
                }
            }
            .control-toggle {
                color: #27476E;
            }
            .play-toggle {
                color: #6bb220;
            }
        }
    }

    .controls-menu {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 400px;
        background: #f0f0f0;
        height: 100%;
        overflow: scroll;
        z-index: 2;
    }
}

@media screen and (max-width: 800px) {
    .app {
        div.animation {
            position: relative;
            background: transparent;
            div.show-controls {
                position: relative;
                .control-toggle {
                    display: none;
                }
                .play-toggle {
                    width: 100%;
                }
                background: #f0f0f0;
            }
        }
        .controls-menu {
            position: relative;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            background: #f0f0f0;
            height: 100%;
            overflow: scroll;
        }
    }
}


#topbar {
    position: absolute;
    left: 0px;
    width: 100%;
    height:60px;
    top: 0px;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.02);
    background-color: #e15c30;
    color: #fff;
    z-index: 1;
}
#toplinks {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    right: 20px;
}
#topbar .header-link {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: #f5f5f5;
    padding-left: 35px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
}
#header-back,
#topbar .header-link {
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.1ex;
}
#toplinks > button,
#toplinks > div {
    margin-left: 30px;
}
body button,
html button {
    cursor: pointer;
    border: none;
    font-family: "Quicksand", sans-serif;
}
#logo {
    position: absolute;
    top: 20px;
    left: 10px;
}
#logo img {
    width: 200px;
}

#header-logo {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-family: "Quicksand", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    #header-logo {
        font-size: 17px;
        display: none;
    }
}


.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #f4f2ef;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    color: #000;
    min-height: 50%;
    font-size: 18px;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
